import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import chromeExtensionSVG from './chromeExtension.svg';
import githubSVG from './github.svg';
import vscode from './vscode.svg';
import webcomponents from './webcomponents.svg';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    border: '1px solid rgba(0,0,0,0.125)',
    width: '400px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
});

const iconSize = '32px';

const projectData = [
  {
    name: "Open in Vim",
    type: "Visual Studio Code plugin",
    desc: (
      <>
        Seamlessly transition between <a className="styled" href="https://code.visualstudio.com/">vscode</a> and <a className="styled" href="https://www.vim.org/">vim</a>/<a className="styled" href="https://neovim.io/">neovim</a> while preserving cursor position and working directory.
        Provides a slew of options for launching vim, including using vscode's integrated terminal, gvim, and gnome-terminal.
        Has 5000+ installs and 5 stars in the Visual Studio Marketplace.
      </>
    ),
    links: [
      {
        icon: (<img src={vscode} alt="vscode" height={iconSize}/>),
        href: "https://marketplace.visualstudio.com/items?itemName=jonsmithers.open-in-vim",
        tooltip: "Visual Studio Code marketplace"
      },
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/vscode-open-in-vim",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'vim-html-template-literals',
    type: "Vim plugin",
    desc: (
      <>
        Syntax highlighting and indentation for html template literals inside vim.
        Essential for working with <a className="styled" href="https://lit-html.polymer-project.org/">lit-html</a> and Poylmer 3.
        Featured in <a className="styled" href="https://github.com/web-padawan/awesome-lit-html#ide-plugins">awesome-lit-html</a>.
      </>
    ),
    links: [
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/vim-html-template-literals",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'gesture-based todo list',
    type: "React prototype",
    desc: "An experiment in writing an gesture-based list manager using spring-based animations and finding ways to manage the dramatic complexity introduced by gestures and animations. Unfinished and buggy 🐞.",
    links: [
      {
        icon: (<LinkIcon />),
        href: "https://jonsmithers.github.io/animated-todo/",
        tooltip: "demo"
      }
    ]
  },
  {
    name: 'Personal Blog',
    type: "Website",
    desc: (
      <>
        Static generated site built with <a href="https://www.11ty.dev">11ty</a>.
      </>
    ),
    links: [
      {
        icon: (<LinkIcon />),
        href: "https://blog.smithers.dev",
        tooltip: "blog"
      }
    ]
  },
  {
    name: 'promise-dialog',
    type: "Polymer web component",
    desc: "Wraps paper-dialog to elegantly integrate promises into the API. You can very quickly transition a paper-dialog to a promise-dialog to gain access to a promise that resolves/rejects when dialog closes based on which button was clicked (or other logic of your design). In many situations, this allows for drastically cleaner code.",
    links: [
      {
        icon: (<img src={webcomponents} alt="web component" height={iconSize}/>),
        href: "https://www.webcomponents.org/element/jonsmithers/promise-dialog",
        tooltip: "Web Component"
      },
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/promise-dialog",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'OrderSplitter',
    type: "Website",
    desc: "A fun collaboration with co-workers to parse complicated receipts and split food delivery bills with complete precision.",
    links: [
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/order-splitter",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'Boring Colors',
    type: "Chrome Extension",
    desc: "A super-simple \"extension\" that makes the internet boring by banning color.",
    links: [
      {
        icon: (<img src={chromeExtensionSVG} alt="Chrome Extension" height={iconSize} />),
        href: "https://chrome.google.com/webstore/detail/boring-colors/klolbbenemkhaahopnhkgconikpdimbp",
        tooltip: "Chrome Web Store"
      },
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/boring-colors",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'OneLock',
    type: "Chrome extension",
    desc: "An fun little experiment for secure client-to-client communication over untrusted servers.",
    links: [
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/dont-trust-the-server",
        tooltip: "GitHub"
      }
    ]
  },
  {
    name: 'WordSafe',
    type: "CLI",
    desc: "A niche tool for creating, reading, and editing encrypted plaintext.",
    links: [
      {
        icon: (<img src={githubSVG} alt="GitHub"/>),
        href: "https://github.com/jonsmithers/wordsafe",
        tooltip: "GitHub"
      }
    ]
  },
];

function Projects() {
  const classes = useStyles();
  return (
    <div style={{
      display:'flex',
      justifyContent:'space-evenly',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      }}
      className='cardContainer'
    >
      {projectData.map(({name, type, desc, links}) => (
        <Card className={classes.card + ' card'} key={name}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {name}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {type}
            </Typography>
            <Typography component="p">
              {desc}
            </Typography>
            <CardActions>
              {links.map(({icon, href, tooltip}) => {
                return (
                  <a href={href} key={tooltip} target="_blank" rel="noopener noreferrer">
                    <Tooltip title={tooltip}>
                      <Button>
                        {icon}
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
            </CardActions>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default Projects;
