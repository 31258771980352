import React, { Component } from 'react';
import './App.css';
import { onHashChange, goToRoot } from './routing.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import About from './About';
import Projects from './Projects';
import { requestLongAnimationFrame } from './utils.js';

class App extends Component {
  componentDidMount() {
    onHashChange(({hash}) => {
      this.setState({hash});
    });

    document.getElementById('root').addEventListener('scroll', () => {
      this.setState(({headerShadow}) => {
        let expectedHeaderShadow = !!document.getElementById('root').scrollTop;
        if (headerShadow !== expectedHeaderShadow) {
          return {headerShadow: expectedHeaderShadow};
        }
      })
    });
    {
      let img = document.createElement('img');
      img.src = 'img/photo-1433354359170-23a4ae7338c6.jpeg';
      img.addEventListener('load', () =>
        requestLongAnimationFrame(() =>
          document.body.classList.add('loaded')
        )
      );
    }
  }
  onTabSelection = (event, selectedTab) => {
    switch(selectedTab) {
      case 1: {
        window.location.hash="projects"
        break;
      }
      default: {
        goToRoot();
        break;
      }
    }
    this.setState({selectedTab});
  }
  render() {
    let {hash, headerShadow} = this.state || {};
    let selectedTab = ['', 'projects'].indexOf(hash);
    if (selectedTab === 0) {
      document.body.classList.add('open-curtain');
    } else {
      document.body.classList.remove('open-curtain');
    }
    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <header className={headerShadow ? 'scrolled' : ''}>
          <Tabs value={selectedTab === -1 ? 0 : selectedTab} onChange={this.onTabSelection} centered>
            <Tab label="About"></Tab>
            <Tab label="Projects"></Tab>
          </Tabs>
        </header>
        <div style={{height:'48px'}}/>
        <div style={{animation: 'content-slide-up 0.8s'}}>
          {selectedTab === 0  ? <About/> : '' }
          {selectedTab === 1  ? <Projects/> : ''}
          {selectedTab === -1 ? <div> Not found </div> : ''}
        </div>
      </div>
    );
  }
}

export default App;
