export async function requestLongAnimationFrame(cb, approxFrameSize=40) {
  let deadline;
  if (window.requestIdleCallback) {
    do {
      deadline = await new Promise(resolve => requestIdleCallback(newDeadline => resolve(newDeadline)));
    } while (deadline.timeRemaining() < approxFrameSize)
  } else {
    console.warn('requestIdleCallback not present');
  }
  requestAnimationFrame(cb);
}
