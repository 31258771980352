var eventTarget;
try {
  eventTarget = new EventTarget();
} catch(e) {
  console.warn('doing workaround', e);
  eventTarget = document.createElement('div');
}

let hash = null, path = null, query = null;

const hashChanged = () => {
  let newHash = window.decodeURIComponent(window.location.hash.substring(1));
  if (newHash === hash) {
    return;
  } else {
    hash = newHash;
  }
  eventTarget.dispatchEvent(new CustomEvent('hashchange', {
    detail: {
      hash
    }
  }));
}
const urlChanged = () => {
  let newPath  = window.decodeURIComponent(window.location.pathname);
  let newQuery = window.decodeURIComponent(window.location.search.substring(1));
  if (newPath !== path || newQuery !== query) {
    path = newPath;
    query = newQuery;
    eventTarget.dispatchEvent(new CustomEvent('urlchange', {
      detail: {
        path,
        query
      }
    }));
  }
  hashChanged()
}
window.addEventListener('hashchange',       hashChanged);
window.addEventListener('location-changed', urlChanged);
window.addEventListener('popstate',         urlChanged);
window.addEventListener('pushstate',        urlChanged);

urlChanged();

export const onUrlChange = (cb) => {
  eventTarget.addEventListener('urlchange', ({detail}) => cb(detail));
  cb({path, query});
}

export const onHashChange = (cb) => {
  eventTarget.addEventListener('hashchange', ({detail}) => cb(detail));
  cb({hash});
}

export const goToRoot = () => {
  window.history.pushState("", document.title, window.location.pathname + window.location.search);
  urlChanged(); // pushState() bypasses evente listeners
}
